import ScrollMagic from 'scrollmagic';

/*
 * Application
 */
(function($) {
    "use strict";

    jQuery(document).ready(function($) {
        /*-------------------------------
        WINTER CMS FLASH MESSAGE HANDLING
        ---------------------------------*/
        $(document).on('ajaxSetup', function(event, context) {
            // Enable AJAX handling of Flash messages on all AJAX requests
            context.options.flash = true;

            // Enable the StripeLoadIndicator on all AJAX requests
            context.options.loading = $.oc.stripeLoadIndicator;

            // Handle Flash Messages
            context.options.handleFlashMessage = function(message, type) {
                $.oc.flashMsg({ text: message, class: type });
            };

            // Handle Error Messages
            context.options.handleErrorMessage = function(message) {
                $.oc.flashMsg({ text: message, class: 'error' });
            };
        });
    });
}(jQuery));

if (typeof(gtag) !== 'undefined' && typeof(gtag) !== 'function') {
    gtag = function() { console.log('GoogleAnalytics not present.'); }
}

const controller = new ScrollMagic.Controller();

// Example: Toggle class 'active' on '#element' when it enters viewport
const scene = new ScrollMagic.Scene({
    triggerElement: 'body', // Replace with your trigger element selector
    triggerHook: 0,            // Trigger point at middle of viewport
    offset: '150px',
    reverse: true                // Reverse the animation on scroll up
})
.setClassToggle('#desktop-navigation', 'active') // Toggle class 'active' on '#element'
.addTo(controller);                  // Add scene to ScrollMagic controller
